import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getIsUniquePropositionOpen, getUniquePropositionResponses,
	setIsAiCategoriesModalOpen,
	setUniquePropositionOpen, setUniquePropositionResponses,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtnIcon } from '../../../../../shared/images/ai.svg';
import { getSocket } from '../../../../websocket/model/slice';
import { messageQueue } from '../../../../websocket/model/webSocketMiddleware';
import { AiResponse } from '../../ai-response/AiResponse';

type FormData = {
	titleService: string,
	detailsInfo: string,
};

export const UniquePropositionalModal: FC = () => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState,
		formState: { errors, isSubmitSuccessful },
		reset,
	} = useForm<FormData>();

	// States
	const [symbolsCount, setSymbolsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const active = useAppSelector(getIsUniquePropositionOpen);
	const responses = useAppSelector(getUniquePropositionResponses);
	const socket = useAppSelector(getSocket);

	// Обнулить значения полей
	const onResetForm = () => {
		reset({ titleService: '', detailsInfo: '' });
	};

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSymbolsCount(event.target.value.length);
	};

	const onClose = () => {
		dispatch(setUniquePropositionOpen(false));
	};
	const onGoBack = () => {
		dispatch(setUniquePropositionOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
	};

	const onSubmit: SubmitHandler<FormData> = (data) => {
		setIsLoading(true);
		const wsData = JSON.stringify({
			type: 'ai.generator.text.unique_offer',
			body: JSON.stringify({
				...data,
			}),
			id: uuidv4(),
		});

		if	(socket?.readyState === WebSocket.OPEN) {
			socket.send(wsData);
		} else {
			messageQueue.enqueue(wsData);
		}
	};

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			const message = JSON.parse(event.data);
			if (message.type === 'ai.generator.text.unique_offer' && message.status === 'ok') {
				setIsLoading(false);
				dispatch(setUniquePropositionResponses(message.body));
				// onResetForm();
			} else if (message.type === 'ai.generator.text.unique_offer' && message.status === 'error') {
				setIsLoading(false);
			}
		};

		if (socket) {
			socket.addEventListener('message', handleMessage);
		}

		return () => {
			if (socket) {
				socket.removeEventListener('message', handleMessage);
			}
		};
	}, [formState, reset, responses]);

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Уникальное торговое предложение"
		>
			<form className="ai-form" onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="titleService" className="ai-form_label">
					Название компании/товара/услуги
					<textarea
						data-action="not-hotkeys"
						id="titleService"
						{...register('titleService', { required: true })}
						placeholder="Металлопрофиль"
					/>
					{errors.titleService && (
						<span className={css.error} role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				{/* textarea - Дополнительная информация */}
				<label htmlFor="detailsInfo" className={css.addInfo}>
					Дополнительная информация
					<span className={css.symbolsCount}>
						{symbolsCount}
						/200
					</span>
					<textarea
						data-action="not-hotkeys"
						id="detailsInfo"
						{...register('detailsInfo', {
							required: true,
						})}
						placeholder="Лучшее предложение на рынке"
						onChange={handleChange}
						maxLength={200}
					/>
					{errors.detailsInfo && (
						<span className="error" role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<button className="aiSubmit" type="submit" disabled={isLoading}>
					<span className="aiSubmit_btn">Написать</span>
					<AiBtnIcon
						className={classNames('aiSubmit_icon', {
							'ai-modal-disabled': isLoading === true,
						})}
					/>
				</button>
			</form>

			{(isLoading && responses.length == 0) && (
				<Loader
					type="roller"
					color="rgba(57, 150, 218, 1)"
					size={100}
					className="alt-loader"
				/>
			)}

			{responses.length >= 1 && (
				<div className="responsesWrapper">
					{isLoading
						&& (
							<Loader
								type="roller"
								color="rgba(57, 150, 218, 1)"
								size={100}
								className="ai-modal-loader"
							/>
						)}
					<div>
						{responses.map((data) => (
							<AiResponse key={uuid()} text={data} />
						))}
					</div>
				</div>
			)}

		</ModalWrapper>
	);
};
