import React from 'react';
import FilterBox, { FilterBoxData } from '../../../my/FilterBox';
import { useAppDispatch, useAppSelector } from '../../../../../../../../shared/libs/redux';
import { setGalleryTemplateSearch } from '../../../../../../../../entities/templates/gallery/model/slice';
import css from './search-bar.module.scss';
import { workshopTagAPI } from '../../../../../../../../entities/templates/workshop/tag/api/api';
import { GalleryCategoriesBar } from './gallery-categories-bar/gallery-categories-bar';
import SearchInput from '../../../../../../../ui/searchInput/SearchInput';

interface GalleryTemplateSearchBarProps {
	sortingData: FilterBoxData,
}

export const GalleryTemplateSearchBar = ({
	sortingData,
}: GalleryTemplateSearchBarProps) => {
	const dispatch = useAppDispatch();

	const search = useAppSelector(state => state.galleryTemplate.search);

	const setSearch = (value: string) => dispatch(setGalleryTemplateSearch(value));

	const { data } = workshopTagAPI.useGetAllQuery({
		page: 1,
		limit: 127,
	});

	return (
		<div className={css.wrapper}>

			<div className={css.filtration}>
				{
					data?.templateTags && <GalleryCategoriesBar categories={data.templateTags} />
				}

				{/* Поиск */}
				<SearchInput
					styles={css.search}
					placeholder="Поиск по шаблонам"
					searchValue={search}
					onChange={setSearch}
				/>
			</div>

			{/* Сортировка */}
			<FilterBox sortingData={sortingData} />
		</div>
	);
};
