import { sliceTokens } from '.';
import { getText, Token } from '../parser';
import { isWhitespace } from '../parser/utils';
import { Model } from '../editor/types';

export default function split(model: Model, chunkSize: number): Token[][] {
	// Алгоритм разбивки:
	// 1. Из текста берём фрагмент в диапазоне [start, chunkSize]
	// 2. Правую границу фрагмента смещаем до тех пор, пока не найдём место,
	//    в котором можем поделить текст (пробел)
	// 3. Из полученного диапазона достаём кусок токенов и добавляем как чанк
	// 4. Сканирование продолжаем от правой границы диапазона

	// Удалим пустые токены, чтобы не мешались, а также удалим пробелы в начале и в конце сообщения
	model = {
		...model,
		tokens: model.tokens.filter(token => token.value.length > 0),
	};

	const result: Token[][] = [];
	const { text, tokens } = trim(model.tokens);

	let start = 0;
	let end = 0;
	let endBound = 0;

	while (start < text.length) {
		end = start + chunkSize;
		if (end >= text.length) {
			// Дошли до конца
			result.push(sliceTokens(model.tokens, start));
		} else {
			// Подвинем границу влево до ближайшего пробела
			while (end > start && !isWhitespace(text.charCodeAt(end))) {
				end--;
			}

			if (start !== end) {
				// Есть точка деления, уберём пробелы в конце
				endBound = end;
				while (endBound > start && isWhitespace(text.charCodeAt(endBound - 1))) {
					endBound--;
				}

				if (start !== endBound) {
					result.push(sliceTokens(model.tokens, start, endBound));
				}
			} else {
				// Нет точки деления, придётся разрезать как есть
				end = start + chunkSize;
				result.push(sliceTokens(model.tokens, start, end));
			}
		}

		start = end;

		// Подвинем точку старта вперёд, чтобы убрать пробелы
		while (start < text.length && isWhitespace(text.charCodeAt(start))) {
			start++;
		}
	}

	return result;
}

/**
 * Удаляет пробелы в начале и в конце строки
 */
export function trim(tokens: Token[]): { text: string, tokens: Token[] } {
	let text = getText(tokens);
	const m1 = text.match(/^\s+/);
	if (m1) {
		text = text.slice(m1[0].length);
		tokens = sliceTokens(tokens, m1[0].length);
	}

	const m2 = text.match(/\s+$/);
	if (m2) {
		text = text.slice(0, -m2[0].length);
		tokens = sliceTokens(tokens, 0, -m2[0].length);
	}

	return { text, tokens };
}
