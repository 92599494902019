import React from 'react';
import Filter, { FilterProps } from '../../../../../ui/filterBox/Filter';
import { TagMenuProps } from '../../../../../ui/filterBox/tagMenu/TagMenu';

export interface FilterBoxData extends FilterProps, TagMenuProps {
}

interface FilterBoxProps {
	sortingData: FilterBoxData,
}

/* Блок с сортировкой */
const FilterBox = ({
	sortingData,
}: FilterBoxProps) => (
	<div style={{ display: 'inline-block' }}>
		<Filter
			tags={sortingData.tags}
			currentName={sortingData.currentName}
			title={sortingData.title}
		/>
	</div>
);

export default FilterBox;
