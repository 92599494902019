import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../shared/libs/redux';
import { useSelection } from '../../../../../../../../../hooks/template-selection/useSelection';
import { TemplateListWrapper } from '../../../../../../../../../widgets/templates/list-wrapper';
import { PrivateTemplate } from '../../private-template/private-template';
import { useIsMounted } from '../../../../../../../../../hooks/lifecycle/useIsMounted';
import { PrivateTemplateListProps } from '../Templates-list';
import { ITemplateData } from '../../../../../../../../../entities/templates/types';
import css from './style.module.scss';
import { ReactComponent as ArrowCloseIcon } from '../../../../../offer/images/arrow-close.svg';
import { popUpTransitionClasses } from '../../../../../../../../../styles/transitionStyles/transitionStyles';
import {
	privateTemplateSlice,
	toggleLastOpenValues,
} from '../../../../../../../../../entities/templates/private/model/privateTemplateSlice';
import { IValuesToSortByLastOpening } from '../../../../../../../../../entities/templates/private/model/types';

/* Отличается от обычно списка карточек шаблонов разделением по временным отрезкам (в виде выпадающих списков) */
export const DividedTemplateList = ({
	templates,
	isDataLoading,
	onShowContextMenu,
	editableTemplate,
	onFinishEditTemplateTitle,
}: PrivateTemplateListProps) => {
	const dispatch = useAppDispatch();
	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);
	const [isGlobalLoading, setIsGlobalLoading] = useState(false);
	const isMounted = useIsMounted();

	const now = new Date();
	const oneWeekAgo = new Date();
	oneWeekAgo.setDate(now.getDate() - 7);	// Устанавливаем дату 7 дней назад (учитывается и время)
	const todayDate = now.getDate();
	const month = now.getMonth();
	const todaySortedTemplates: ITemplateData[] = [];	// Отсортированные шаблоны за сегодня
	const yesterdaySortedTemplates: ITemplateData[] = [];	// Отсортированные шаблоны за вчера
	const lastWeekSortedTemplates: ITemplateData[] = [];	// Отсортированные шаблоны за последнюю неделю
	const lastMonthSortedTemplates: ITemplateData[] = [];	// Отсортированные шаблоны за последний месяц
	const remainingTemplates: ITemplateData[] = [];	// Отсортированные шаблоны, открытые позже 1 месяца назад

	if (templates) {
		for (let i = 0; i < templates?.length; i++) {
			const lastOpenDate = new Date(templates[i].lastOpened);
			const templateMonth = lastOpenDate.getUTCMonth() + 1;	// Месяц (время UTC)
			const day = lastOpenDate.getUTCDate();	// День (время UTC)
			if	(day === todayDate) {
				todaySortedTemplates.push(templates[i]);
			} else if (day === (todayDate - 1)) {
				yesterdaySortedTemplates.push(templates[i]);
			} else if (lastOpenDate >= oneWeekAgo) {
				lastWeekSortedTemplates.push(templates[i]);
			} else if (month === templateMonth) {
				lastMonthSortedTemplates.push(templates[i]);
			} else {
				remainingTemplates.push(templates[i]);
			}
		}
	}

	const {
		lastWeekOpen, lastMonthOpen, todayOpen, yesterdayOpen,
	} = useSelector(privateTemplateSlice.selectors.getLastOpenValues);

	const handleToggle = (accordionType: keyof IValuesToSortByLastOpening) => {
		dispatch(toggleLastOpenValues({ accordionType }));
	};

	const setGlobalLoadingState = (id: string | null) => {
		if (isMounted.current) {
			setIsGlobalLoading(!!id);
		}
	};

	useSelection();

	return (
		<>
			<div>
				<div className={css.openedList}>
					<span className={`${css.arrow} ${todayOpen ? css.rotate : ''}`}>
						<ArrowCloseIcon />
					</span>
					<button
						type="button"
						onClick={() => handleToggle('todayOpen')}
						className={css.openedListBtn}
					>
						Сегодня
					</button>
				</div>
				<CSSTransition
					in={todayOpen}
					timeout={200}
					unmountOnExit
					classNames={popUpTransitionClasses}
				>
					<div className={css.panel}>
						<TemplateListWrapper isDataLoading={isDataLoading}>
							{
								todaySortedTemplates.map(template => {
									let isEditTitle = false;
									if (editableTemplate === template.id) isEditTitle = true;
									return (
										<PrivateTemplate
											key={template.id}
											template={template}
											onShowContextMenu={onShowContextMenu}
											selected={selectedList.includes(template.id)}
											isEditableTitle={isEditTitle}
											onFinishEditTemplateTitle={onFinishEditTemplateTitle}
											isGlobalLoading={isGlobalLoading}
											setGlobalLoading={setGlobalLoadingState}
										/>
									);
								})
							}
						</TemplateListWrapper>
					</div>
				</CSSTransition>

			</div>

			<div>
				<div className={css.openedList}>
					<span className={`${css.arrow} ${yesterdayOpen ? css.rotate : ''}`}>
						<ArrowCloseIcon />
					</span>
					<button
						type="button"
						onClick={() => handleToggle('yesterdayOpen')}
						className={css.openedListBtn}
					>
						Вчера
					</button>
				</div>

				<CSSTransition
					in={yesterdayOpen}
					timeout={200}
					unmountOnExit
					classNames={popUpTransitionClasses}
				>
					<div className={css.panel}>
						<TemplateListWrapper isDataLoading={isDataLoading}>
							{
								yesterdaySortedTemplates.map(template => {
									let isEditTitle = false;
									if (editableTemplate === template.id) isEditTitle = true;
									return (
										<PrivateTemplate
											key={template.id}
											template={template}
											onShowContextMenu={onShowContextMenu}
											selected={selectedList.includes(template.id)}
											isEditableTitle={isEditTitle}
											onFinishEditTemplateTitle={onFinishEditTemplateTitle}
											isGlobalLoading={isGlobalLoading}
											setGlobalLoading={setGlobalLoadingState}
										/>
									);
								})
							}
						</TemplateListWrapper>
					</div>
				</CSSTransition>
			</div>

			<div>
				<div className={css.openedList}>
					<span className={`${css.arrow} ${lastWeekOpen ? css.rotate : ''}`}>
						<ArrowCloseIcon />
					</span>
					<button
						type="button"
						onClick={() => handleToggle('lastWeekOpen')}
						className={css.openedListBtn}
					>
						На прошлой неделе
					</button>
				</div>
				<CSSTransition
					in={lastWeekOpen}
					timeout={200}
					unmountOnExit
					classNames={popUpTransitionClasses}
				>
					<div className={css.panel}>
						<TemplateListWrapper isDataLoading={isDataLoading}>
							{
								lastWeekSortedTemplates.map(template => {
									let isEditTitle = false;
									if (editableTemplate === template.id) isEditTitle = true;
									return (
										<PrivateTemplate
											key={template.id}
											template={template}
											onShowContextMenu={onShowContextMenu}
											selected={selectedList.includes(template.id)}
											isEditableTitle={isEditTitle}
											onFinishEditTemplateTitle={onFinishEditTemplateTitle}
											isGlobalLoading={isGlobalLoading}
											setGlobalLoading={setGlobalLoadingState}
										/>
									);
								})
							}
						</TemplateListWrapper>
					</div>
				</CSSTransition>
			</div>

			<div>
				<div className={css.openedList}>
					<span className={`${css.arrow} ${lastMonthOpen ? css.rotate : ''}`}>
						<ArrowCloseIcon />
					</span>
					<button
						type="button"
						onClick={() => handleToggle('lastMonthOpen')}
						className={css.openedListBtn}
					>
						В прошлом месяце
					</button>
				</div>
				<CSSTransition
					in={lastMonthOpen}
					timeout={200}
					unmountOnExit
					classNames={popUpTransitionClasses}
				>
					<div className={css.panel}>
						<TemplateListWrapper isDataLoading={isDataLoading}>
							{
								lastMonthSortedTemplates.map(template => {
									let isEditTitle = false;
									if (editableTemplate === template.id) isEditTitle = true;
									return (
										<PrivateTemplate
											key={template.id}
											template={template}
											onShowContextMenu={onShowContextMenu}
											selected={selectedList.includes(template.id)}
											isEditableTitle={isEditTitle}
											onFinishEditTemplateTitle={onFinishEditTemplateTitle}
											isGlobalLoading={isGlobalLoading}
											setGlobalLoading={setGlobalLoadingState}
										/>
									);
								})
							}
						</TemplateListWrapper>
					</div>
				</CSSTransition>
			</div>

			<TemplateListWrapper isDataLoading={isDataLoading}>
				{
					remainingTemplates.map(template => {
						let isEditTitle = false;
						if (editableTemplate === template.id) isEditTitle = true;
						return (
							<PrivateTemplate
								key={template.id}
								template={template}
								onShowContextMenu={onShowContextMenu}
								selected={selectedList.includes(template.id)}
								isEditableTitle={isEditTitle}
								onFinishEditTemplateTitle={onFinishEditTemplateTitle}
								isGlobalLoading={isGlobalLoading}
								setGlobalLoading={setGlobalLoadingState}
							/>
						);
					})
				}
			</TemplateListWrapper>
		</>
	);
};
