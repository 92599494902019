import React, {
	FC, useEffect, useRef, useState, 
} from 'react';
import { fontSize } from 'html2canvas/dist/types/css/property-descriptors/font-size';
import css from './style.module.scss';
import { ReactComponent as CopyIcon } from '../../assets/images/copy-icon.svg';

interface IProps {
	text: string,
}

export const AiResponse: FC<IProps> = ({ text }) => {
	const [copyHint, setCopyHint] = useState('Копировать');

	const hintRef = useRef<HTMLSpanElement | null>(null); // Ссылка на блок
	const [hintWidth, setHintWidth] = useState(0); // Состояние для ширины

	const handleCopy = () => {
		navigator.clipboard.writeText(text).then(() => {
			// Устанавливаем состояние для отображения уведомления
			setCopyHint('Текст скопирован!');

			// Скрываем сообщение через 2 секунды
			setTimeout(() => {
				setCopyHint('Копировать');
			}, 2000);
		});
	};

	useEffect(() => {
		// Функция для обновления ширины блока
		const updateWidth = () => {
			if (hintRef.current) {
				setHintWidth(hintRef.current.offsetWidth); // обновляем ширину
			}
		};

		// Сначала получаем ширину блока при монтировании
		updateWidth();

		// Добавляем обработчик события для обновления ширины при изменении размеров окна
		window.addEventListener('resize', updateWidth);

		// Убираем обработчик события при размонтировании компонента
		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, []); // Пустой массив зависимостей, чтобы эффект сработал только один раз

	return (
		<div className="ai-response">
			<div className={css.header}>
				<button type="button" className={css.btn}>
					<CopyIcon
						onClick={handleCopy}
						className={css.btn__icon}
					/>
					<span
						className={css.btn__hint}
						ref={hintRef}
						style={{ left: `-${(hintWidth / 2) / 2 + 5}px` }}
					>
						{copyHint}
					</span>
				</button>
			</div>
			{text}
		</div>
	);
};
