import parse, {
	getLength,
	getText,
	IParserOptions,
	ITokenLink,
	ITokenText,
	normalize,
	Token,
	TokenFormat,
	TokenType,
} from '../parser';
import type { ICutText, ITokenFormatUpdate, TextRange } from './types';
import {
	createToken,
	isCustomLink,
	isSolidToken,
	sliceToken,
	splitToken,
	tokenForPos,
	tokenRange,
	toLink,
	toText,
} from './utils';
import { objectMerge } from '../utils/objectMerge';
import { FontFamily, FontSize } from '../editor/types';
import { setFontColor, setFontFamily, setFontSize } from '../editor/update';
import Editor from '../editor';

export { tokenForPos };
export type { ICutText, ITokenFormatUpdate, TextRange };

/**
 * Вставляет указанный текст `text` в текстовую позицию `pos` списка токенов
 * @return Обновлённый список токенов
 */
// export function insertText(tokens: Token[], pos: number, text: string, options: Partial<IParserOptions>): Token[] {
// 	return updateTokens(tokens, text, pos, pos, options);
// }

/**
 * Заменяет текст указанной длины в текстовой позиции `pos` на новый `text` (обновляет токены).
 * @return Обновлённый список токенов
 */
// export function replaceText(
// 	tokens: Token[],
// 	pos: number,
// 	len: number,
// 	text: string,
// 	options: Partial<IParserOptions>,
// ): Token[] {
// 	return updateTokens(tokens, text, pos, pos + len, options);
// }

/**
 * Удаляет текст указанной длины из списка токенов в указанной позиции
 */
export function removeText(editor: Editor, tokens: Token[], pos: number, len: number, options: Partial<IParserOptions>): Token[] {
	/* Пустой токен у нас получается если мы выделяем слово и нажимаем backspace - в этом случае
	* отрабатывает логика по сохранению стилей в этот пустой токен. Для этого обработаем случай
	* удаления этого пустого токена */
	if (tokens.length === 1 && tokens[0].value.length === 0) {
		return tokens;
	}

	return updateTokens(editor, '', pos, pos + len, options);
}

/**
 * Вырезает текст из диапазона `from:to` и возвращает его и изменённую строку
 */
export function cutText(editor: Editor, from: number, to: number, options: Partial<IParserOptions>): ICutText {
	return {
		cut: normalize(sliceTokens(editor.model.tokens, from, to)),
		tokens: removeText(editor, editor.model.tokens, from, to - from, options),
	};
}

/**
 * Возвращает формат для указанной позиции в строке
 */
export function getFormat(tokens: Token[], pos: number): TokenFormat {
	const { index } = tokenForPos(tokens, pos);
	return index !== -1 ? tokens[index].format : 0;
}

/**
 * Выставляет текстовый формат `format` для всех токенов из диапазона `pos, pos + len`.
 * Если `len` не указано, вставляет sticky-метку в указанную позицию `pos`
 * @param breakSolid Применять форматирование внутри «сплошных» токенов, то есть
 * можно один сплошной токен разделить на несколько и указать им разное форматирование
 * @param tokens Это изначальные токены до форматирования.
 * @param format
 * @param pos
 * @param len
 */
export function setFormat(
	tokens: Token[],
	format: ITokenFormatUpdate | TokenFormat,
	pos: number,
	breakSolid?: boolean,
	len = 0,
): Token[] {
	if (!tokens.length) {
		// Пограничный случай: выставляем формат пустой строке
		return [createToken('', undefined, applyFormat(0, format), undefined, undefined, undefined, true)];
	}

	const [start, end] = tokenRange(tokens, pos, pos + len, !breakSolid);

	if (start.index === -1 || end.index === -1 || end.index < start.index) {
		// Невалидные данные, ничего не делаем
		return tokens;
	}

	const startToken = tokens[start.index];

	if (end.index === start.index) {
		if (end.offset === start.offset) {
			// Вставляем sticky-формат в указанную точку
			tokens = applyFormatAt(tokens, start.index, format, start.offset, 0);
		} else {
			// Изменения в пределах одного токена, разделим его
			tokens = applyFormatAt(tokens, start.index, format, start.offset, end.offset - start.offset);
		}
	} else {
		// Затронули несколько токенов
		tokens = tokens.slice();

		// Обновляем промежуточные токены, пока индексы точные
		for (let i = start.index + 1, nextFormat: TokenFormat; i < end.index; i++) {
			nextFormat = applyFormat(tokens[i].format, format);
			if (tokens[i].format !== nextFormat) {
				tokens[i] = objectMerge(tokens[i], {
					format: nextFormat,
				});
			}
		}

		// Убедимся, что границы позиций не находились на границах токенов,
		// иначе поставим sticky-форматирование
		if (end.offset !== 0) {
			tokens = applyFormatAt(tokens, end.index, format, 0, end.offset);
		}

		if (start.offset < startToken.value.length) {
			tokens = applyFormatAt(tokens, start.index, format, start.offset, startToken.value.length - start.offset);
		}
	}

	return normalize(tokens);
}

/**
 * Выставляет текстовый формат `format` для всех токенов из диапазона `pos, pos + len`.
 * Если `len` не указано, вставляет sticky-метку в указанную позицию `pos`
 * @param tokens
 * @param fontFam
 * @param pos
 * @param breakSolid Применять форматирование внутри «сплошных» токенов, то есть
 * можно один сплошной токен разделить на несколько и указать им разное форматирование
 * @param len
 */
export function setFontFam(
	tokens: Token[],
	fontFam: FontFamily,
	pos: number,
	breakSolid?: boolean,
	len = 0,
): Token[] {
	if (!tokens.length && fontFam) {
		// Пограничный случай: выставляем шрифт пустой строке
		return [createToken('', undefined, undefined, fontFam, undefined, undefined, true)];
	}

	const [start, end] = tokenRange(tokens, pos, pos + len, !breakSolid);

	if (start.index === -1 || end.index === -1 || end.index < start.index) {
		// Невалидные данные, ничего не делаем
		return tokens;
	}

	const startToken = tokens[start.index];

	if (end.index === start.index) {
		if (end.offset === start.offset) {
			// Вставляем sticky-формат в указанную точку
			tokens = applyFontFamilyAt(tokens, start.index, fontFam, start.offset, 0);
		} else {
			// Изменения в пределах одного токена, разделим его
			tokens = applyFontFamilyAt(tokens, start.index, fontFam, start.offset, end.offset - start.offset);
		}
	} else {
		// Затронули несколько токенов
		tokens = tokens.slice();

		// Обновляем промежуточные токены, пока индексы точные
		for (let i = start.index + 1, nextFontFam: FontFamily; i < end.index; i++) {
			nextFontFam = fontFam;
			if (tokens[i].fontFamily !== nextFontFam) {
				tokens[i] = objectMerge(tokens[i], {
					fontFamily: nextFontFam,
				});
			}
		}

		// Убедимся, что границы позиций не находились на границах токенов,
		// иначе поставим sticky-форматирование
		if (end.offset !== 0) {
			tokens = applyFontFamilyAt(tokens, end.index, fontFam, 0, end.offset);
		}

		if (start.offset < startToken.value.length) {
			// eslint-disable-next-line max-len
			tokens = applyFontFamilyAt(tokens, start.index, fontFam, start.offset, startToken.value.length - start.offset);
		}
	}

	return normalize(tokens);
}

/**
 * Выставляет размер текста для всех токенов из диапазона `pos, pos + len`.
 * Если `len` не указано, вставляет sticky-метку в указанную позицию `pos`
 * @param breakSolid Применять форматирование внутри «сплошных» токенов, то есть
 * можно один сплошной токен разделить на несколько и указать им разное форматирование
 */
export function FormatFontSize(
	tokens: Token[],
	fontSize: FontSize,
	pos: number,
	breakSolid?: boolean,
	len = 0,
): Token[] {
	if (!tokens.length) {
		// Пограничный случай: выставляем размер пустой строке
		return [createToken('', undefined, undefined, undefined, fontSize, undefined, true)];
	}

	const [start, end] = tokenRange(tokens, pos, pos + len, !breakSolid);

	if (start.index === -1 || end.index === -1 || end.index < start.index) {
		// Невалидные данные, ничего не делаем
		return tokens;
	}

	const startToken = tokens[start.index];

	if (end.index === start.index) {
		if (end.offset === start.offset) {
			// Вставляем sticky-формат в указанную точку
			tokens = applyFontSizeAt(tokens, start.index, fontSize, start.offset, 0);
		} else {
			// Изменения в пределах одного токена, разделим его
			tokens = applyFontSizeAt(tokens, start.index, fontSize, start.offset, end.offset - start.offset);
		}
	} else {
		// Затронули несколько токенов
		tokens = tokens.slice();

		// Обновляем промежуточные токены, пока индексы точные
		for (let i = start.index + 1, nextFontSize: FontSize; i < end.index; i++) {
			nextFontSize = fontSize;
			if (tokens[i].fontSize !== nextFontSize) {
				tokens[i] = objectMerge(tokens[i], {
					fontSize: nextFontSize,
				});
			}
		}

		// Убедимся, что границы позиций не находились на границах токенов,
		// иначе поставим sticky-форматирование
		if (end.offset !== 0) {
			tokens = applyFontSizeAt(tokens, end.index, fontSize, 0, end.offset);
		}

		if (start.offset < startToken.value.length) {
			// eslint-disable-next-line max-len
			tokens = applyFontSizeAt(tokens, start.index, fontSize, start.offset, startToken.value.length - start.offset);
		}
	}

	return normalize(tokens);
}

/**
 * Выставляет цвет текста для всех токенов из диапазона `pos, pos + len`.
 * Если `len` не указано, вставляет sticky-метку в указанную позицию `pos`
 * @param tokens
 * @param fontColor
 * @param pos
 * @param breakSolid Применять форматирование внутри «сплошных» токенов, то есть
 * можно один сплошной токен разделить на несколько и указать им разное форматирование
 * @param len
 */
export function FormatFontColor(
	tokens: Token[],
	fontColor: string,
	pos: number,
	breakSolid?: boolean,
	len = 0,
): Token[] {
	if (!tokens.length) {
		// Пограничный случай: выставляем размер пустой строке
		return [createToken(
			'',
			undefined,
			undefined,
			undefined,
			undefined,
			fontColor,
			true,
		)];
	}

	const [start, end] = tokenRange(tokens, pos, pos + len, !breakSolid);

	if (start.index === -1 || end.index === -1 || end.index < start.index) {
		// Невалидные данные, ничего не делаем
		return tokens;
	}

	const startToken = tokens[start.index];

	if (end.index === start.index) {
		if (end.offset === start.offset) {
			// Вставляем sticky-формат в указанную точку
			tokens = applyFontColorAt(tokens, start.index, fontColor, start.offset, 0);
		} else {
			// Изменения в пределах одного токена, разделим его
			tokens = applyFontColorAt(tokens, start.index, fontColor, start.offset, end.offset - start.offset);
		}
	} else {
		// Затронули несколько токенов
		tokens = tokens.slice();

		// Обновляем промежуточные токены, пока индексы точные
		for (let i = start.index + 1, nextFontColor: string; i < end.index; i++) {
			nextFontColor = fontColor;
			if (tokens[i].fontSize !== nextFontColor) {
				tokens[i] = objectMerge(tokens[i], {
					color: nextFontColor,
				});
			}
		}

		// Убедимся, что границы позиций не находились на границах токенов,
		// иначе поставим sticky-форматирование
		if (end.offset !== 0) {
			tokens = applyFontColorAt(tokens, end.index, fontColor, 0, end.offset);
		}

		if (start.offset < startToken.value.length) {
			// eslint-disable-next-line max-len
			tokens = applyFontColorAt(tokens, start.index, fontColor, start.offset, startToken.value.length - start.offset);
		}
	}

	return normalize(tokens);
}

/**
 * Возвращает фрагмент строки форматирования
 */
export function sliceTokens(tokens: Token[], from: number, to?: number): Token[] {
	if (!tokens.length) {
		return tokens;
	}

	const fullLen = getLength(tokens);

	if (to == null) {
		to = fullLen;
	} else if (to < 0) {
		to += fullLen;
	}

	if (from < 0) {
		from += fullLen;
	}

	if (from < 0 || from > fullLen || to < 0 || to > fullLen || to < from) {
		console.warn(`Invalid range: ${from}:${to}. Max length: ${fullLen}`);
		return [];
	}

	if (from === to) {
		return [];
	}

	const [start, end] = tokenRange(tokens, from, to);

	if (start.index === end.index) {
		// Получаем фрагмент в пределах одного токена
		const t = tokens[start.index];
		if (start.offset === 0 && end.offset === t.value.length) {
			// Токен целиком
			return [t];

		}
		return [expandToken(sliceToken(tokens[start.index], start.offset, end.offset))];

	}
	const [, left] = splitToken(tokens[start.index], start.offset);

	const [right] = splitToken(tokens[end.index], end.offset);
	return normalize([
		expandToken(left),
		...tokens.slice(start.index + 1, end.index),
		expandToken(right),
	]);
}

/**
 * Делает указанный диапазон ссылкой на `link`. Работать должен именно с токенами.
 */
// eslint-disable-next-line default-param-last
export function setLink(tokens: Token[], link: string | null, pos: number, len = 0, sticky?: boolean): Token[] {
	const [start, end] = tokenRange(tokens, pos, pos + len);

	if (start.index === -1 || end.index === -1) {
		console.warn('Invalid range:', { pos, len });
		return tokens;
	}

	let token: Token;
	const nextTokens = tokens.slice();

	// Меняем промежуточные токены на ссылки
	for (let i = start.index + 1; i < end.index; i++) {
		nextTokens[i] = toLinkOrText(nextTokens[i], link, sticky);
	}

	// Обновляем концевые токены
	if (start.index === end.index) {
		// Попали в один токен
		token = nextTokens[start.index];
		const [left, _mid] = splitToken(token, start.offset);
		const [mid, right] = splitToken(_mid, end.offset - start.offset);
		const next = toLinkOrText(mid, link, sticky);
		nextTokens.splice(start.index, 1, left, next, right);
	} else {
		let left: Token;
		let right: Token;

		token = nextTokens[end.index];
		[left, right] = splitToken(token, end.offset);
		nextTokens.splice(end.index, 1, toLinkOrText(left, link, sticky), right);

		token = nextTokens[start.index];
		[left, right] = splitToken(token, start.offset);
		nextTokens.splice(start.index, 1, left, toLinkOrText(right, link, sticky));
	}

	return normalize(nextTokens);
}

/**
 * Вставляет указанный текст `text` в текстовую позицию `pos` списка токенов
 * @return Обновлённый список токенов
 */
// export function mdInsertText(
// 	tokens: Token[],
// 	pos: number,
// 	text: string,
// 	options: Partial<IParserOptions>,
// ): Token[] {
// 	return mdUpdateTokens(tokens, text, pos, pos, options);
// }

/**
 * Заменяет текст указанной длины в текстовой позиции `pos` на новый `text`
 * @return Обновлённый список токенов
 */
// export function mdReplaceText(
// 	tokens: Token[],
// 	pos: number,
// 	len: number,
// 	text: string,
// 	options: Partial<IParserOptions>,
// ): Token[] {
// 	return mdUpdateTokens(tokens, text, pos, pos + len, options);
// }

/**
 * Удаляет текст указанной длины из списка токенов в указанной позиции
 */
// export function mdRemoveText(tokens: Token[], pos: number, len: number, options: Partial<IParserOptions>): Token[] {
// 	return mdUpdateTokens(tokens, '', pos, pos + len, options);
// }

/**
 * Вырезает текст из диапазона `from:to` и возвращает его и изменённую строку
 */
// export function mdCutText(tokens: Token[], from: number, to: number, options: Partial<IParserOptions>): ICutText {
// 	return {
// 		cut: parse(getText(tokens).slice(from, to), options),
// 		tokens: mdRemoveText(tokens, from, to - from, options),
// 	};
// }

/** Проверяет есть ли в модели текстовые токены */
export function isHaveTextToken(
	tokens: Token[],
): boolean {
	// Проверим есть ли текстовые токены
	const textTokens = tokens.reduce(
		(accumulator: Token[], token) => {
			if	(token.type === TokenType.Text) {
				accumulator.push(token);
				return accumulator;
			}
			return accumulator;
		}, []
	);

	// Проверим текстовые токены на пустоту
	const isHaveText = textTokens.some(token => token.value.length > 0);
	return isHaveText;
}

/**
 * Универсальный метод для обновления списка токенов: добавление, удаление и замена
 * текста в списке указанных токенов
 * @param editor
 * @param value
 * @param from
 * @param to
 * @param options - опции для парсера
 */
export function updateTokens(
	editor: Editor,
	value: string,
	from: number,
	to: number,
	options: Partial<IParserOptions>
): Token[] {
	// Токены до обновления
	const tokens = editor.getTokens();
	if (!tokens.length) {
		return parse(value, options);
	}

	/* Найдем позиции в токенах для указанного диапазона */
	const [start, end] = tokenRange(tokens, from, to);

	if (start.index === -1 || end.index === -1) {
		// Такого не должно быть
		console.warn('Invalid location:', {
			from, to, start, end,
		});
		return tokens;
	}

	// Токены ДО токена, с которого начинается диапазон
	const prefix = tokens.slice(0, start.index);

	// Токены после токена, на котором диапазон заканчивается
	const suffix = tokens.slice(end.index + 1);

	const endToken = tokens[end.index];

	/* При вставке скопированного текста бизнес логика требует вычислять токен по границе с кареткой (например мы
	вставляем текст между двумя словами, одно жирное, второе курсивом, но между ними пробел и кликнув мышкой на границе
	слов, мы как раз попадем в этот пробел, а это третий токен без форматирования и, так как это пользователю будет не
	понятно внедрена возможность указать стартовый токен принудительно) */
	// let startToken: Token = options.prototypeToken ? options.prototypeToken : tokens[start.index];
	let startToken: Token = tokens[start.index];
	let textBound = start.offset + value.length;
	let nextValue = startToken.value.slice(0, start.offset)
        + value + endToken.value.slice(end.offset);

	let nextTokens: Token[] = parse(nextValue, options, startToken);

	/* startToken - чтобы взять из него настройки (размер шрифта, цвет и тд)
	nextTokens - содержит последний токен (если есть) находясь на котором пользователь совершает ввод,
	а также новые токены (они формируются из символов ввода). */
	if (checkSaveStyleOnUpdateModel(tokens, nextTokens)
	) {
		// 	Найдем первый токен из диапазона
		const firstTextToken = tokens.find(token => token.type === TokenType.Text)
		if (firstTextToken) {
			editor.tokenStyle = {
				fontSize: firstTextToken.fontSize,
				fontFamily: firstTextToken.fontFamily,
				color: firstTextToken.color,
				format: firstTextToken.format,
				textAlign: firstTextToken.textAlign,
				lineHeight: firstTextToken.lineHeight,
			}
			nextTokens = nextTokens.map(token => {
				return {
					...token,
					fontSize: firstTextToken.fontSize,
					fontFamily: firstTextToken.fontFamily,
					color: firstTextToken.color,
					format: firstTextToken.format,
					textAlign: firstTextToken.textAlign,
					lineHeight: firstTextToken.lineHeight,
				};
			})
		}
	}

	if (nextTokens.length) {
		// Вставляем/заменяем фрагмент
		// eslint-disable-next-line no-return-assign
		nextTokens.forEach(t => t.format = startToken.format);

		// Применяем форматирование из концевых токенов, но только если можем
		// сделать это безопасно: применяем только для текста
		if	(options.prototypeToken) {
			nextTokens = setFormat(
				nextTokens,
				options.prototypeToken.format,
				textBound,
				undefined,
				nextValue.length - textBound
			);
			nextTokens = setFontColor(
				nextTokens,
				options.prototypeToken.color,
				textBound,
				textBound + nextValue.length,
				{
					parse: {},
					nowrap: false,
					resetFormatOnNewline: false,
				},
			);
			nextTokens = setFontFamily(
				nextTokens,
				options.prototypeToken.fontFamily,
				textBound,
				textBound + nextValue.length,
				{
					parse: {},
					nowrap: false,
					resetFormatOnNewline: false,
				},
			);
			nextTokens = setFontSize(
				nextTokens,
				options.prototypeToken.fontSize,
				textBound,
				textBound + nextValue.length,
				{
					parse: {},
					nowrap: false,
					resetFormatOnNewline: false,
				},
			);
		} else {
			if (startToken.format !== endToken.format
				|| startToken.color !== endToken.color
				|| startToken.fontSize !== endToken.fontSize
				|| startToken.fontFamily !== endToken.fontFamily
				|| startToken.textAlign !== endToken.textAlign
				|| startToken.lineHeight !== endToken.lineHeight
			) {
				const splitPoint = tokenForPos(nextTokens, textBound);
				// eslint-disable-next-line max-len
				if (splitPoint.index !== -1
					&& textBound !== nextValue.length
					&& nextTokens.slice(splitPoint.index).every(t => t.type === TokenType.Text)
				) {
					nextTokens = setFormat(
						nextTokens,
						endToken.format,
						textBound,
						undefined,
						nextValue.length - textBound
					);
					nextTokens = setFontColor(
						nextTokens,
						endToken.color,
						textBound,
						textBound + nextValue.length,
						{
							parse: {},
							nowrap: false,
							resetFormatOnNewline: false,
						},
					);
					nextTokens = setFontFamily(
						nextTokens,
						endToken.fontFamily,
						textBound,
						textBound + nextValue.length,
						{
							parse: {},
							nowrap: false,
							resetFormatOnNewline: false,
						},
					);
					nextTokens = setFontSize(
						nextTokens,
						endToken.fontSize,
						textBound,
						textBound + nextValue.length,
						{
							parse: {},
							nowrap: false,
							resetFormatOnNewline: false,
						},
					);
				}
			}
		} {

		}

		// Проверяем пограничные случаи:
		// — начало изменяемого диапазона находится в пользовательской ссылке:
		//   сохраним ссылку
		const tokenRemoved = start.offset === 0 && to - from > startToken.value.length;
		if (isCustomLink(startToken) && !tokenRemoved) {
			const { link } = startToken;
			let sticky: boolean | undefined;

			// Проверяем, куда пришло редактирование: если добавляем текст
			// в самом конце ссылки или в самом начале, то не распространяем
			// ссылку на этот текст
			if (start.offset === startToken.value.length) {
				let len = start.offset;

				// Пограничный случай: ссылка, внутри которой есть форматирование
				// и мы пишем в конец форматирования
				// <a>foo <b>bar</b>| baz</a>
				const nextSibling = tokens[start.index + 1];
				if (nextSibling?.type === TokenType.Link && nextSibling.link === startToken.link) {
					len += value.length;
				} else if (startToken.sticky) {
					// Включено sticky-форматирование: значит, мы дописываем ссылку.
					// Разрешаем сделать это до первого символа-раделителя
					const m = value.match(/[\s!,.:;?]/);
					if (m) {
						len += m.index || 0;
						sticky = false;
					} else {
						len += value.length;
						sticky = true;
					}
				}
				nextTokens = setLink(nextTokens, link, 0, len, sticky);
			} else if (start.offset === 0 && from === to) {
				// Пишем текст в самом начале ссылки
				nextTokens = setLink(nextTokens, link, value.length, startToken.value.length);
			}
		}

		// if (isCustomLink(endToken) && value) {
		//     nextTokens = setLink(nextTokens, endToken.link, start.offset, textBound - start.offset);
		// }
	}

	return normalize([...prefix, ...nextTokens, ...suffix]);
}

/**
 * Проверяет нужно ли сохранить форматирование текста в память при обновлении модели. Необходимо нам в случае полного
 * удаления текста из модели для сохранения форматирования при последующем вводе текста.
 * Пример: создали текстовый компонент, написали 'waka', сделали текст жирным. Дальше выделяем 'waka' через crtl + A и
 * удаляем. В этом случае у нас остается пустой текстовый редактор, но мы должны запомнить, что у нас текст был "жирным".
 * При таком поведении мы записываем форматирование во внутреннее поле редактора.
 */
function checkSaveStyleOnUpdateModel(prevTokens: Token[], newTokens: Token[]): boolean {
	const isOldTokensHaveText = isHaveTextToken(prevTokens);
	const isNewTokensHaveText = isHaveTextToken(newTokens);
	return isOldTokensHaveText && !isNewTokensHaveText;
}

/**
 * Универсальный метод для обновления списка токенов для markdown-синтаксиса.
 * Из-за некоторых сложностей с инкрементальным обновлением токенов, мы будем
 * просто модифицировать строку и заново её парсить: производительность парсера
 * должно хватить, чтобы делать это на каждое изменение.
 */
// eslint-disable-next-line max-len
function mdUpdateTokens(tokens: Token[], value: string, from: number, to: number, options: Partial<IParserOptions>): Token[] {
	const prevText = getText(tokens);
	const nextText = prevText.slice(0, from) + value + prevText.slice(to);
	return parse(nextText, options);
}

/**
 * Применяет изменения формата `update` для токена `tokens[tokenIndex]`,
 * если это необходимо
 */
function applyFormatAt(
	tokens: Token[],
	tokenIndex: number,
	update: ITokenFormatUpdate | TokenFormat,
	pos: number,
	len: number,
): Token[] {
	const token = tokens[tokenIndex];
	const format = applyFormat(token.format, update);

	if (token.format === format) {
		// У токена уже есть нужный формат
		return tokens;
	}

	let nextTokens: Token[];

	if (pos === 0 && len === token.value.length) {
		// Частный случай: меняем формат у всего токена
		nextTokens = [objectMerge(token, { format })];
	} else {
		// Делим токен на части. Если это специальный токен типа хэштэга
		// или команды, превратим его в обычный текст
		const [left, _mid] = splitToken(token, pos);
		const [mid, right] = splitToken(_mid, len);
		mid.format = format;

		nextTokens = [left, mid, right];
		if (isSolidToken(token)) {
			nextTokens = nextTokens.map(t => toText(t));
		}
		(nextTokens[1] as ITokenText).sticky = len === 0;
	}

	return normalize([
		...tokens.slice(0, tokenIndex),
		...nextTokens,
		...tokens.slice(tokenIndex + 1),
	]);
}

/**
 * Применяет изменения размера текста `update` для токена `tokens[tokenIndex]`,
 * если это необходимо
 */
function applyFontFamilyAt(
	tokens: Token[],
	tokenIndex: number,
	update: FontFamily,
	pos: number,
	len: number,
): Token[] {
	const token = tokens[tokenIndex];

	if (token.fontFamily === update) {
		// У токена уже есть нужный шрифт
		return tokens;
	}

	let nextTokens: Token[];

	if (pos === 0 && len === token.value.length) {
		// Частный случай: меняем формат у всего токена
		nextTokens = [objectMerge(token, { fontFamily: update })];
	} else {
		// Делим токен на части. Если это специальный токен типа хэштэга
		// или команды, превратим его в обычный текст
		const [left, _mid] = splitToken(token, pos);
		const [mid, right] = splitToken(_mid, len);
		mid.fontFamily = update;

		nextTokens = [left, mid, right];
		if (isSolidToken(token)) {
			nextTokens = nextTokens.map(t => toText(t));
		}
		(nextTokens[1] as ITokenText).sticky = len === 0;
	}

	const res = normalize([
		...tokens.slice(0, tokenIndex),
		...nextTokens,
		...tokens.slice(tokenIndex + 1),
	]);
	return res;
}

/**
 * Применяет изменения размера текста `update` для токена `tokens[tokenIndex]`,
 * если это необходимо
 */
function applyFontSizeAt(
	tokens: Token[],
	tokenIndex: number,
	update: FontSize,
	pos: number,
	len: number,
): Token[] {
	const token = tokens[tokenIndex];

	if (token.fontSize === update) {
		// У токена уже есть нужный шрифт
		return tokens;
	}

	let nextTokens: Token[];

	if (pos === 0 && len === token.value.length) {
		// Частный случай: меняем формат у всего токена
		nextTokens = [objectMerge(token, { fontSize: update })];
	} else {
		// Делим токен на части. Если это специальный токен типа хэштэга
		// или команды, превратим его в обычный текст
		const [left, _mid] = splitToken(token, pos);
		const [mid, right] = splitToken(_mid, len);
		mid.fontSize = update;

		nextTokens = [left, mid, right];
		if (isSolidToken(token)) {
			nextTokens = nextTokens.map(t => toText(t));
		}
		(nextTokens[1] as ITokenText).sticky = len === 0;
	}

	return normalize([
		...tokens.slice(0, tokenIndex),
		...nextTokens,
		...tokens.slice(tokenIndex + 1),
	]);
}

/**
 * Применяет изменения цвета текста `update` для токена `tokens[tokenIndex]`, если это необходимо.
 */
function applyFontColorAt(
	tokens: Token[],
	tokenIndex: number,
	update: string,
	pos: number,
	len: number,
): Token[] {
	const token = tokens[tokenIndex];

	if (token.color === update) {
		// У токена уже есть нужный шрифт
		return tokens;
	}

	let nextTokens: Token[];

	if (pos === 0 && len === token.value.length) {
		// Частный случай: меняем формат у всего токена
		nextTokens = [objectMerge(token, { color: update })];
	} else {
		// Делим токен на части. Если это специальный токен типа хэштэга
		// или команды, превратим его в обычный текст
		const [left, _mid] = splitToken(token, pos);
		const [mid, right] = splitToken(_mid, len);
		mid.color = update;

		nextTokens = [left, mid, right];
		if (isSolidToken(token)) {
			nextTokens = nextTokens.map(t => toText(t));
		}
		(nextTokens[1] as ITokenText).sticky = len === 0;
	}

	return normalize([
		...tokens.slice(0, tokenIndex),
		...nextTokens,
		...tokens.slice(tokenIndex + 1),
	]);
}
/**
 * Применяет данные из `update` формату `format`: добавляет и/или удаляет указанные
 * типы форматирования.
 * Если в качестве `update` передали сам формат, то он и вернётся
 */
export function applyFormat(format: TokenFormat, update: ITokenFormatUpdate | TokenFormat): TokenFormat {
	if (typeof update === 'number') {
		return update;
	}

	if (update.add) {
		format |= update.add;
	}

	if (update.remove) {
		format &= ~update.remove;
	}

	return format;
}

function toLinkOrText(token: Token, link: string | null, sticky?: boolean): ITokenLink | ITokenText {
	return link ? toLink(token, link, sticky) : toText(token);
}

/**
 * Если переданный токен - это ссылка, проверяет добавлена ли она пользователем или распознана автоматически. У
 * автоматически распознанной ссылки проверяет текст на соответствие ссылке, если не соответствует, то превратит в
 * текст.
 */
function expandToken(token: Token): ITokenText | ITokenLink {
	// if (token.type === TokenType.Link) {
	// 	if (!token.auto) {
	// 		return token;
	// 	}
	//
	// 	// Авто-ссылка: проверим её содержимое: если текст соответствует ссылке,
	// 	// то оставим её, иначе превратим в текст
	// 	const parseToken = parse(token.value, { link: true })[0] as ITokenText | ITokenLink;
	// 	parseToken.format = token.format;
	// 	return parseToken;
	// }

	return toText(token);
}
