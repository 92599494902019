import React from 'react';
import css from './list-wrapper.module.scss';
import { SkeletonTemplates } from '../../skeleton/skeleton';

interface TemplateListWrapperProps {
    isDataLoading: boolean,
    children: React.ReactNode,
}

const SKELETON_ITEM_COUNT = 33;

/* Добавляет скелетон во время загрузки */
export const TemplateListWrapper = ({
	children,
	isDataLoading,
}: TemplateListWrapperProps) => {
	if (isDataLoading) {
		return <SkeletonTemplates count={SKELETON_ITEM_COUNT} />;
	}

	return (
		<div className={css.wrapper}>
			{children}
		</div>
	);
};
