import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import useFilterData, { FromFilterData } from '../../../../../../../hooks/filter/useFilterData';
import useGetTemplatesMy from '../../../../../../../hooks/template/private/useGetTemplatesMy';
import { onPrivateTemplateCreate } from '../../../../../../../helpers/create-template/onPrivateTemplateCreate';
import { PrivateTemplateList } from './list/Templates-list';
import { DeletePrivateTemplateModal } from './modals/delete';
import { MovePrivateTemplateModal } from './modals/move/move';
import { EmptyPlugList } from '../../../../../../../widgets/templates/empty-list';
import { PrivateTemplateSearchBar } from './search-bar/search-bar';
import {
	setPrivateTemplateSelectedList,
} from '../../../../../../../entities/templates/private/model/privateTemplateSlice';
import { useContextMenu } from '../../../../../../../widgets/context-menu/libs/useContextMenu';
import { PrivateTemplateContextMenu } from './context-menu/private-template-context-menu';
import { templatePrivateAPI } from '../../../../../../../entities/templates/private/api/api';
import { DividedTemplateList } from './list/list-with-last-open-sorting/DividedTemplateList';

/* Страница - карточки приватных шаблонов */
export const PrivateTemplatesPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);
	const privateTemplateList = useAppSelector(state => state.privateTemplate.privateTemplates);
	const accountTeams = useAppSelector(state => state.personalAccount.account.teams);

	// Логика для переименования шаблона
	const [updateTemplate] = templatePrivateAPI.useUpdateMutation();
	const [editableTemplate, setEditableTemplate] = useState<string | null>(null);
	const updateTemplateName = (id: string, name: string) => {
		setEditableTemplate(null);
		updateTemplate({ template: id, name });
	};
	const onContextMenuRename = () => {
		setEditableTemplate(selectedList[0]);
	};

	const [isTemplateCreating, setIsTemplateCreating] = useState(false);
	const {
		currentFilter,
		sortingData,
	} = useFilterData(FromFilterData.PRIVATE_TEMPLATE);

	const onTemplateCreate = () => onPrivateTemplateCreate(navigate, setIsTemplateCreating);

	const cleanSelectedList = () => dispatch(setPrivateTemplateSelectedList([]));

	const { isLoading } = useGetTemplatesMy(cleanSelectedList, currentFilter.sortBy);

	const {
		menuRef,
		show,
		position,
		onShowContextMenu,
		closeContextMenu,
	} = useContextMenu(cleanSelectedList);

	return (
		<>
			<PrivateTemplateSearchBar
				sortingData={sortingData}
				isLoading={isTemplateCreating}
				onTemplateCreate={onTemplateCreate}
			/>
			{
				(!privateTemplateList || privateTemplateList.length)
					? (
						<>
							{
								currentFilter.sortBy === 'last_opened'
									? (
										<DividedTemplateList
											isDataLoading={isLoading}
											templates={privateTemplateList}
											onShowContextMenu={onShowContextMenu}
											editableTemplate={editableTemplate}
											onFinishEditTemplateTitle={updateTemplateName}
										/>
									)
									: (
										<PrivateTemplateList
											isDataLoading={isLoading}
											templates={privateTemplateList}
											onShowContextMenu={onShowContextMenu}
											editableTemplate={editableTemplate}
											onFinishEditTemplateTitle={updateTemplateName}
										/>
									)
							}

							<DeletePrivateTemplateModal choicedTemplateData={selectedList} />
							<MovePrivateTemplateModal currentTeams={accountTeams} />

							<PrivateTemplateContextMenu
								closeContextMenu={closeContextMenu}
								show={show}
								ref={menuRef}
								position={position}
								onRename={onContextMenuRename}
							/>
						</>
					)
					: (
						<EmptyPlugList
							isCreateBtn
							isNeedProcessing={isTemplateCreating}
							btnText="Создать шаблон"
							onClick={onTemplateCreate}
							title="Создайте первый шаблон"
							description="Используйте конструктор для создания коммерческого предложения"
						/>
					)
			}
		</>
	);
};
