export enum ServerWebSocketType {
	aiGenerationsBalance = 'ai.balance',
	connect = 'connect',

	privateTemplateConnect = 'sketch.private_template.template.connect',
	privateTemplateDisconnect = 'sketch.private_template.template.disconnect',

	workshopTemplateConnect = 'sketch.workshop_template.template.connect',
	workshopTemplateDisconnect = 'sketch.workshop_template.template.disconnect',
}
