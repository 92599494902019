import { useDispatch } from 'react-redux';
import { templatePrivateAPI } from '../../../../../../../entities/templates/private/api/api';
import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { useAppSelector } from '../../../../../../../shared/libs/redux';
import {
	setIsConfirmPrivateDeleteModalActive,
	setPrivateTemplateSelectedList,
} from '../../../../../../../entities/templates/private/model/privateTemplateSlice';

export const useDeleteTemplates = () => {
	const dispatch = useDispatch();

	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);

	const setActive = (value: boolean) => {
		dispatch(setIsConfirmPrivateDeleteModalActive(value));
	};

	const [del, { isLoading }] = templatePrivateAPI.useDeleteMutation();

	const deleteTemplate = async () => {
		const { length } = selectedList;

		if (length) {
			try {
				await del({ templates: selectedList })
					.unwrap();

				if (length === 1) {
					notificationSuccess('Шаблон успешно удален!');
				} else {
					notificationSuccess('Шаблоны успешно удалены!');
				}
			} catch (e) {
				if (length === 1) {
					notificationError('Возникла ошибка при удалении шаблона!');
				} else {
					notificationError('Возникла ошибка при удалении шаблонов!');
				}
			} finally {
				setActive(false);
				dispatch(setPrivateTemplateSelectedList([]));
			}
		}
	};

	return {
		isLoading,
		setActive,
		deleteTemplate,
	};
};
