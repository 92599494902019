import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { templateTabs } from './model/tabs-data';
import {
	LINK_COLLECTION_TEMPLATE_CUSTOM,
	LINK_COLLECTION_TEMPLATE_GALLERY,
	LINK_COLLECTION_TEMPLATE_STORAGE,
	LINK_COLLECTION_TEMPLATE_TEAM,
} from './model/consts';
import { TabsHeaderLayout } from '../../../layouts/tabs-header';
import { TemplateTeamPage } from './teams';
import { TemplateGalleryPage } from './galleries';
import { ContentLayout } from '../../../layouts/content';
import { TemplateCustomPage } from './custom';
import { PrivateTemplatesPage } from './private/ui/Private-templates-page';

export enum TemplateFromData {
	PRIVATE = 'PRIVATE',
	GALLERY = 'GALLERY',
	TEAM = 'TEAM',
}

export type TemplateFromDataType = TemplateFromData.GALLERY | TemplateFromData.TEAM | TemplateFromData.PRIVATE;

export const ITEM_LIMIT_PER_PAGE = 24;

const SectionTemplate = () => (
	<ContentLayout>
		<TabsHeaderLayout title="Шаблоны" tabsData={templateTabs}>
			<div className="list-container">
				<Routes>
					<Route
						path={LINK_COLLECTION_TEMPLATE_STORAGE}
						element={<PrivateTemplatesPage />}
					/>
					<Route
						path={LINK_COLLECTION_TEMPLATE_GALLERY}
						element={<TemplateGalleryPage />}
					/>
					<Route
						path={LINK_COLLECTION_TEMPLATE_TEAM}
						element={<TemplateTeamPage />}
					/>
					{/* Заказные */}
					<Route
						path={LINK_COLLECTION_TEMPLATE_CUSTOM}
						element={<TemplateCustomPage />}
					/>
				</Routes>
			</div>
		</TabsHeaderLayout>
	</ContentLayout>
);

export default SectionTemplate;
