import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../shared/libs/classNames';
import SearchInput from '../../../../components/ui/searchInput/SearchInput';
import { SettingsButton } from '../../../../shared/ui';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import {
	workshopModuleActions,
	workshopModuleSelectors,
	workshopModuleSlice,
} from '../../../../entities/workshop-module';
import css from './WorkshopModulesModal.module.scss';
import {
	DeleteWorkshopModuleModal, FilterWorkshopModulesByTags,
} from '../../../../features/manage-workshop-module';
import { WorkshopModulesModalList } from '../modules-list/WorkshopModulesModalList';
import { UnbindWorkshopTagModal } from '../../../../features/manage-workshop-module-tags';
import { workshopTagSlice } from '../../../../entities/workshop-tag';
import { WorkshopModulesCategoriesBar } from '../categories/bar/WorkshopModulesCategoriesBar';

interface WorkshopModulesModalProps {
	className?: string,
}

export const WorkshopModulesModal = ({ className }: WorkshopModulesModalProps) => {
	const dispatch = useAppDispatch();

	const [search, setSearch] = useState('');

	const isActive = useSelector(workshopModuleSlice.selectors.getIsModulesModalActive);

	const isEditModulesActive = useSelector(workshopModuleSlice.selectors.getIsEditModuleModalActive);
	const isDeleteModuleActive = useSelector(workshopModuleSlice.selectors.getIsDeletingModuleModalActive);

	const isUnbindTagActive = useSelector(workshopTagSlice.selectors.getIsUnbindingModalActive);
	const isBindingTagActive = useSelector(workshopModuleSlice.selectors.getIsBindingTagModuleModalActive);

	const isEditCategoryActive = useAppSelector(state => state.category.modalsActive.isEditCategoryModalActive);
	const isDeleteCategoryActive = useAppSelector(state => state.category.modalsActive.isDeletingCategoryModalActive);

	const onModuleClick = useSelector(workshopModuleSelectors.getOnModuleClick);

	const handleModal = () => {
		dispatch(workshopModuleActions.setIsModulesModalActive(false));
		dispatch(workshopModuleActions.setCategories([]));
	};

	const openEditModulesWindow = () => {
		dispatch(workshopModuleActions.setIsEditModuleModalActive(true));
	};

	return (
		<NewModal
			active={isActive}
			closeFunc={handleModal}
			modalInModalActive={
				isEditModulesActive
					|| isDeleteModuleActive
					|| isUnbindTagActive
					|| isBindingTagActive
					|| isEditCategoryActive
					|| isDeleteCategoryActive
			}
		>
			<div className={classNames(css.wrapper, {}, [className])}>
				<div className={css.content}>
					<div className={css.search_block}>
						<SearchInput
							onChange={setSearch}
							placeholder="Поиск по модулям"
							searchValue={search}
							styles={css.search}
						/>
						<FilterWorkshopModulesByTags />
						<div className={css.scroll_wrapper} />
					</div>
					<WorkshopModulesModalList onModuleClick={onModuleClick} search={search} />
				</div>

				{/* Блок с поиском и категориями */}
				<div className={css.manage_bar}>
					<div className={css.categories}>
						<WorkshopModulesCategoriesBar />
					</div>
					<SettingsButton onClick={openEditModulesWindow} />
				</div>
			</div>
			<UnbindWorkshopTagModal />
			<DeleteWorkshopModuleModal />
		</NewModal>
	);
};
