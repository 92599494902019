import cx from 'classnames';
import { Slider } from '../../../../../../../../../widgets/slider';
import { IWorkshopTemplateTag } from '../../../../../../../../../entities/templates/workshop/tag/api/types';
import css from './gallery-categories-bar.module.scss';
import { GalleryCategory } from './gallery-category/gallery-category';
import {
	useAdvancedSearchSelectedTags,
} from '../../../../../../../../../shared/libs/templates/useAdvancedSearchSelectedTags';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../shared/libs/redux';
import {
	setGalleryTagFilter, setGalleryTemplateSearch,
} from '../../../../../../../../../entities/templates/gallery/model/slice';
import { ITagData } from '../../../../../../../../../entities/modules/tag/api/types';
import { ReactComponent as ResetIcon } from '../../../../../../../../../image/reset.svg';

const initialCategory = {
	id: 0,
	name: 'Все категории',
};

interface GalleryCategoriesBarProps {
	categories: IWorkshopTemplateTag[];
}

export const GalleryCategoriesBar = ({ categories }: GalleryCategoriesBarProps) => {
	const dispatch = useAppDispatch();

	const selectedCategories = useAppSelector(state => state.galleryTemplate.tagFilter);

	const setSelectedCategories = (tags: ITagData[]) => {
		dispatch(setGalleryTagFilter(tags));
		dispatch(setGalleryTemplateSearch(''));
	};

	const selectAllCategories = () => {
		setSelectedCategories([]);
		dispatch(setGalleryTemplateSearch(''));
	};

	const { handleTag: handleCategory } = useAdvancedSearchSelectedTags(selectedCategories, setSelectedCategories);

	return (
		<div className={css.wrapper}>
			<Slider buttonScrollDistance={200} height={34} controlSize="small">
				<GalleryCategory
					category={initialCategory}
					onClick={selectAllCategories}
					selected={!selectedCategories.length}
				/>
				{categories?.map(category => {
					const selected = selectedCategories.includes(category);

					return (
						<GalleryCategory
							key={category.id}
							category={category}
							selected={selected}
							onClick={handleCategory}
						/>
					);
				})}
			</Slider>
			<div className={cx(css.reset_icon, selectedCategories.length && css.focus)}>
				<ResetIcon onClick={selectAllCategories} />
			</div>
		</div>
	);
};
