import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GalleryTemplateList } from './list/template-list';
import useFilterData, { FromFilterData } from '../../../../../../../hooks/filter/useFilterData';
import { EmptyPlugList } from '../../../../../../../widgets/templates/empty-list';
import { onPrivateTemplateCreate } from '../../../../../../../helpers/create-template/onPrivateTemplateCreate';
import { useGetGalleryTemplates } from '../utils/useGetGalleryTemplates';
import { useAppSelector } from '../../../../../../../shared/libs/redux';
import { GalleryTemplateSearchBar } from './search-bar/search-bar';

export const TemplateGalleryPage = () => {
	const navigate = useNavigate();

	const templates = useAppSelector(state => state.galleryTemplate.galleryTemplates);

	const {
		currentFilter,
		sortingData,
	} = useFilterData(FromFilterData.TEMPLATE);

	const [isTemplateCreating, setIsTemplateCreating] = useState(false);

	const { isLoading } = useGetGalleryTemplates(currentFilter.sortBy);

	const onTemplateCreate = () => onPrivateTemplateCreate(navigate, setIsTemplateCreating);

	return (
		<div>

			{/* Поиск по шаблонам и категории для выбора шаблонов */}
			<GalleryTemplateSearchBar sortingData={sortingData} />

			{
				((templates && templates.length) || isLoading)
					? <GalleryTemplateList templates={templates} isDataLoading={isLoading} />
					: (
						<EmptyPlugList
							isNeedProcessing={isTemplateCreating}
							isCreateBtn
							btnText="Создать шаблон"
							onClick={onTemplateCreate}
							title="Скоро здесь будут шаблоны"
							description="А пока можете воспользоваться конструктором"
						/>
					)
			}
		</div>
	);
};
